/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import {
  Table, IconButton, Button, Modal,
} from 'rsuite';
import TrashIcon from '@rsuite/icons/Trash';
import { connect } from 'react-redux';
import { removeApiRequest } from './store/apis/actions';

function ActionCell({
  removeApi, rowData, dataKey, ...props
}) {
  const { id, revision } = rowData;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSubmit = () => {
    removeApi({ apiId: id, revision });
    handleClose();
  };
  return (
    <Table.Cell {...props}>
      <IconButton appearance="subtle" onClick={handleOpen} icon={<TrashIcon />} />
      <Modal size="sm" open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Destroy API</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Api
            {' '}
            <strong>{id}</strong>
            {' '}
            will be Destroyed Completely
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
          <Button onClick={handleSubmit} appearance="primary" color="red">
            Destroy
          </Button>
        </Modal.Footer>
      </Modal>
    </Table.Cell>
  );
}

function TableEndpoint({ apisData = [], removeApi }) {
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (apisData) setLoading(false);
  }, [apisData]);

  const getData = () => {
    if (sortColumn && sortType) {
      return apisData && apisData.length && apisData.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
          x = x.charCodeAt();
        }
        if (typeof y === 'string') {
          y = y.charCodeAt();
        }
        if (sortType === 'asc') {
          return x - y;
        }
        return y - x;
      });
    }
    return apisData;
  };

  const handleSortColumn = (sC, sT) => {
    setSortColumn(sC);
    setSortType(sT);
  };

  return (
    <div style={{
      height: '100%', padding: '20px',
    }}
    >
      <Table
        data={getData()}
        height={500}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={loading}
      >
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>id</Table.HeaderCell>
          <Table.Cell dataKey="id" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.Cell dataKey="name" />
        </Table.Column>
        <Table.Column flexGrow={2}>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.Cell dataKey="description" />
        </Table.Column>
        <Table.Column flexGrow={2}>
          <Table.HeaderCell>Updated</Table.HeaderCell>
          <Table.Cell>
            {(rowData) => <span>{new Date(rowData.updated_at * 1000).toLocaleString()}</span>}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={100}>
          <Table.HeaderCell>Action</Table.HeaderCell>
          <ActionCell removeApi={removeApi} dataKey="id" />
        </Table.Column>
      </Table>
    </div>
  );
}

const mapStateToProps = ({ apis }) => ({
  apisData: apis,
});

const mapDispatchToProps = (dispatch) => ({
  removeApi: (data) => dispatch(removeApiRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableEndpoint);
