import { combineReducers } from 'redux';

import { apisReducer } from './apis/reducer';
import { resourcesReducer } from './resources/reducer';
import { authReducer } from './user/reducer';
import { configReducer } from './config/reducer';
import { toastsReducer } from './toasts/reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  apis: apisReducer,
  resources: resourcesReducer,
  config: configReducer,
  toasts: toastsReducer,
});

export default rootReducer;
