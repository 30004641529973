import { Config } from './constants';

const initialState = null;

export const configReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Config.getConfigSuccess:
      return action.data;
    default:
      return state;
  }
};
