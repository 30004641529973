import React from 'react';
import {
  Routes, Route, BrowserRouter,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import Layout from './components/Layout';
import Toasts from './components/Toasts';
import configureStore from './store/configureStore';

import CreateEndpoint from './CreateEndpoint';
import TableEndpoint from './TableEndpoint';
import TableResource from './TableResource';
import CreateResource from './CreateResource';

// import customHistory from './history';

import 'rsuite/dist/rsuite.min.css';
import './styles.scss';

const store = configureStore();

class App extends React.PureComponent {
  render() {
    return (
      <Provider store={store}>

        <BrowserRouter>
          <Layout>
            <Routes>
              <Route path="/" element={<TableEndpoint />} />
              <Route path="/create" element={<CreateEndpoint />} />
              <Route path="/:id" element={<TableResource />} />
              <Route path="/:id/create" element={<CreateResource />} />
              <Route path="/:id/:proc_id" element={<CreateResource />} />
            </Routes>
            <Toasts />
          </Layout>
        </BrowserRouter>

      </Provider>
    );
  }
}

export default App;
