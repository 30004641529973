/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Form, Schema, ButtonToolbar, Button, Input, InputNumber, Content,
  SelectPicker, Checkbox, CheckboxGroup, Toggle,
} from 'rsuite';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import Tooltip from 'rsuite/Tooltip';
import Whisper from 'rsuite/Whisper';
import { createResourceRequest, editResourceRequest } from './store/resources/actions';

const model = Schema.Model({
  path: Schema.Types.StringType().pattern(/^\/[0-9a-z-{}/]+$/, 'Please enter valid path').isRequired('This field required'),
  process_id: Schema.Types.StringType().pattern(/^[0-9]+$/, 'Please enter valid process_id').isRequired('This field required'),
  method: Schema.Types.StringType().isOneOf(
    ['POST', 'GET', 'PUT', 'PATCH', 'DELETE'],
    'Choose methods from the list',
  ).isRequired('This field required'),
  api_login: Schema.Types.StringType().pattern(/^[0-9]+$/, 'Please enter valid API Login').isRequired('This field required'),
  timeout: Schema.Types.NumberType().min(1, 'Please enter Timeout').isRequired('This field required'),
});

const methods = [
  { label: 'GET', value: 'GET' },
  { label: 'POST', value: 'POST' },
  { label: 'PUT', value: 'PUT' },
  { label: 'PATCH', value: 'PATCH' },
  { label: 'DELETE', value: 'DELETE' },
];

const initFormValues = {
  path: '',
  description: '',
  method: null,
  timeout: 29,
  async: false,
  proxy: [],
  process_id: '',
  api_login: '',
  api_login_enabled: true,
};

// eslint-disable-next-line arrow-body-style
const ToggleCustom = React.forwardRef(({ value, ...otherProps }, ref) => {
  return <Toggle {...otherProps} checked={value} size="sm" ref={ref} />;
});

const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" rows={2} ref={ref} />);
const InputSec = React.forwardRef((props, ref) => <InputNumber {...props} min={0} max={29} ref={ref} />);
const InputProcId = React.forwardRef((props, ref) => <Input {...props} ref={ref} />);
const InputKey = React.forwardRef((props, ref) => <Input {...props} ref={ref} placeholder="API Key" />);
const Select = React.forwardRef((props, ref) => (
  <SelectPicker
    {...props}
    cleanable={false}
    searchable={false}
    appearance="default"
    placeholder="Select"
    style={{ width: 300 }}
    ref={ref}
  />
));

function CreateResource({ createResource, editResource, resources: resourcesData }) {
  const [val, setVal] = useState(initFormValues);
  const [checkTrigger, setCheckTrigger] = useState('none');
  const navigate = useNavigate();

  const formRef = useRef();

  const handleVal = (e) => {
    setVal({
      ...e,
      api_login: e.api_login_enabled ? e.api_login : undefined,
    });
  };

  const { pathname } = useLocation();
  const firstPath = pathname.split('/')[1];
  const secondPath = pathname.split('/')[2];

  useEffect(() => {
    setCheckTrigger('none');
    let active;
    if (resourcesData && resourcesData.length) {
      active = resourcesData.find((el) => el.id == secondPath);
      if (active) {
        setVal({
          path: active.path,
          description: active.description || '',
          method: active.method,
          timeout: active.timeout,
          async: active.async || false,
          process_id: String(active.process_id),
          api_login: String(active.public?.api_login) || undefined,
          api_login_enabled: active.public?.api_login || undefined,
          proxy: [active.proxy_headers ? 'proxy_headers' : '', active.proxy_raw_body ? 'proxy_raw_body' : ''].filter((el) => el !== ''),
        });
      } else {
        setVal(initFormValues);
      }
    }
  }, [pathname, resourcesData]);

  const handleSubmit = () => {
    const {
      path, process_id, method, api_login, timeout, api_login_enabled,
    } = val;
    const active = resourcesData.find((el) => el.id == secondPath);
    setCheckTrigger('change');
    if (!path || (!(process_id && /^[0-9]+$/.test(String(process_id)))) || !method || !timeout || (api_login_enabled && !(api_login && /^[0-9]+$/.test(String(api_login))))) return;
    if (active) {
      editResource({
        revision: active.revision,
        id: secondPath,
        apiId: firstPath,
        path: val.path,
        description: val.description,
        method: val.method,
        timeout: Number(val.timeout),
        async: val.async,
        process_id: Number(val.process_id),
        proxy_headers: val.proxy.includes('proxy_headers'),
        proxy_raw_body: val.proxy.includes('proxy_raw_body'),
        public: (api_login_enabled && api_login) ? {
          api_login: Number(val.api_login),
        } : undefined,
      });
    } else {
      createResource({
        apiId: firstPath,
        path: val.path,
        description: val.description,
        method: val.method,
        timeout: Number(val.timeout),
        async: val.async,
        process_id: Number(val.process_id),
        proxy_headers: val.proxy.includes('proxy_headers'),
        proxy_raw_body: val.proxy.includes('proxy_raw_body'),
        public: (api_login_enabled && api_login) ? {
          api_login: Number(val.api_login),
        } : undefined,
      }, (procId) => {
        navigate(`/${firstPath}/${procId}`);
      });
    }
  };
  return (
    <Form
      formValue={val}
      onChange={handleVal}
      onSubmit={handleSubmit}
      model={model}
      checkTrigger={checkTrigger}
      ref={formRef}
    >
      <Form.Group controlId="path">
        <Form.ControlLabel>Path</Form.ControlLabel>
        <Form.Control name="path" />
      </Form.Group>
      <Form.Group controlId="method">
        <Form.ControlLabel>Method</Form.ControlLabel>
        <Form.Control name="method" accepter={Select} data={methods} />
      </Form.Group>
      <Form.Group controlId="process_id">
        <Form.ControlLabel>Process ID</Form.ControlLabel>
        <Form.Control name="process_id" accepter={InputProcId} />
      </Form.Group>
      <Form.Group>
        <Form.Group controlId="description">
          <Form.ControlLabel>
            Description
            {' '}
            <em>(Optional)</em>
          </Form.ControlLabel>
          <Form.Control rows={5} name="description" accepter={Textarea} />
        </Form.Group>
        <Form.Group controlId="timeout">
          <Form.ControlLabel>
            Timeout
          </Form.ControlLabel>
          <Form.Control name="timeout" accepter={InputSec} />
        </Form.Group>
        <Form.Group controlId="api_login_enabled" style={{ marginBottom: '12px' }}>
          <Form.ControlLabel>
            Public
            {' '}
            <Whisper
              trigger="hover"
              placement="top"
              controlId="control-id-top"
              speaker={
                <Tooltip>Provide Corezoid API Key for Public Routes</Tooltip>
                  }
            >
              <span style={{ marginLeft: '2px', position: 'relative', top: '-1px' }}><HelpOutlineIcon /></span>
            </Whisper>
          </Form.ControlLabel>
          <Form.Control name="api_login_enabled" accepter={ToggleCustom} />
        </Form.Group>
        {val.api_login_enabled && (
        <Form.Group controlId="api_login">
          <Form.Control name="api_login" accepter={InputKey} />
        </Form.Group>
        )}
        <Form.Group
          controlId="async"
        >
          <Form.ControlLabel>
            Async
          </Form.ControlLabel>
          <Form.Control name="async" accepter={ToggleCustom} />
        </Form.Group>
        <Form.Group controlId="proxy">
          <Form.ControlLabel>Proxy:</Form.ControlLabel>
          <Form.Control name="proxy" accepter={CheckboxGroup} inline>
            <Checkbox value="proxy_headers">proxy headers</Checkbox>
            <Checkbox value="proxy_raw_body">proxy raw body</Checkbox>
          </Form.Control>
        </Form.Group>
        <ButtonToolbar>
          <Button type="submit" appearance="primary" style={{ marginBottom: '20px' }}>
            {secondPath === 'create' ? (
              <span>Add</span>
            ) : (
              <span>Modify</span>
            )}
          </Button>
        </ButtonToolbar>
      </Form.Group>
    </Form>
  );
}

const mapDispatchToProps = (dispatch) => ({
  createResource: (ops, callback) => dispatch(createResourceRequest(ops, callback)),
  editResource: (ops) => dispatch(editResourceRequest(ops)),
});

export default connect(null, mapDispatchToProps)(CreateResource);
