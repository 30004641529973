/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Content, Loader,
} from 'rsuite';
import CreateForm from './CreateForm';

function CreateResource({ resources: resourcesData }) {
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [pathname]);

  return (
    <Content style={{ height: '100%', overflowY: 'scroll', position: 'relative' }}>
      {loading ? (
        <div style={{
          position: 'absolute', top: '45%', transform: 'translate(50%, 50%)', left: '45%',
        }}
        >
          <Loader center backdrop={false} size="lg" speed="slow" />
        </div>
      ) : (
        <div style={{
          display: 'flex', height: '100%', maxHeight: '100%', justifyContent: 'center', marginTop: '40px', marginBottom: '40px',
        }}
        >
          <CreateForm resources={resourcesData} />
        </div>
      )}

    </Content>
  );
}

const mapStateToProps = ({ resources }) => ({
  resources,
});

export default connect(mapStateToProps)(CreateResource);
