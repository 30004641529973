/* eslint-disable max-len */
import { Auth } from './constants';

export const meRequest = () => ({ type: Auth.meRequest });
export const meSuccess = (data) => ({ type: Auth.meSuccess, data });
export const meError = (err) => ({ type: Auth.meError, err });

export const authUrlRequest = () => ({ type: Auth.authUrlRequest });
export const authUrlSuccess = () => ({ type: Auth.authUrlSuccess });
export const authUrlError = (err) => ({ type: Auth.authUrlError, err });

export const logoutRequest = () => ({ type: Auth.logoutRequest });
export const logoutSuccess = () => ({ type: Auth.logoutSuccess });
export const logoutError = (err) => ({ type: Auth.logoutError, err });

export const getTokenRequest = (data) => ({ type: Auth.getTokenRequest, data });
export const getTokenSuccess = (data) => ({ type: Auth.getTokenSuccess, data });
export const getTokenError = (err) => ({ type: Auth.getTokenError, err });

export const refreshTokenRequest = () => ({ type: Auth.refreshTokenRequest });
export const refreshTokenSuccess = (data) => ({ type: Auth.refreshTokenSuccess, data });
export const refreshTokenError = (err) => ({ type: Auth.refreshTokenError, err });
