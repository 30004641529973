import { all, fork } from 'redux-saga/effects';
import apisSaga from './apis/saga';
import resourcesSaga from './resources/saga';
import authSaga from './user/saga';
import configSaga from './config/saga';
import toastsSaga from './toasts/saga';

// eslint-disable-next-line func-names
const rootSaga = function* () {
  yield all([
    fork(apisSaga),
    fork(resourcesSaga),
    fork(authSaga),
    fork(configSaga),
    fork(toastsSaga),
  ]);
};

export default rootSaga;
