/* eslint-disable camelcase */
import {
  call, put, takeEvery, all, fork, select,
} from 'redux-saga/effects';
import { api } from '../../helpers/api';
import {
  getResourcesSuccess, getResourcesError,
  createResourceSuccess, createResourceError,
  removeResourceSuccess, removeResourceError,
  editResourceSuccess, editResourceError,
} from './actions';

import { createToastRequest } from '../toasts/actions';

import {
  authUrlRequest,
} from '../user/actions';

import { Resources } from './constants';

const getAuth = (state) => state.auth?.auth;

export function* fetchResources(ops) {
  const authData = yield select(getAuth);
  const { data } = ops;
  const { id } = data || {};
  try {
    const response = yield call(api, `/apis/${id}/paths`, 'GET', null, authData);
    if (response.ok) {
      const parsed = yield response.json();
      yield put(getResourcesSuccess(parsed));
    } else if (response.status === 401) {
      yield put(authUrlRequest());
    } else {
      yield put(getResourcesError('failed to get resources'));
      yield put(createToastRequest({ type: 'error', text: 'Failed to fetch Resources' }));
    }
  } catch (e) {
    yield put(getResourcesError(e));
    yield put(createToastRequest({ type: 'error', text: 'Failed to fetch Resources' }));
  }
}

export function* createResource(ops) {
  const authData = yield select(getAuth);
  const { data, callback } = ops;
  const { apiId, process_id, ...other } = data || {};
  try {
    const response = yield call(api, `/apis/${apiId}/paths`, 'POST', { ...other, process_id: Number(process_id) }, authData);
    if (response.ok) {
      const parsed = yield response.json();
      yield put(createResourceSuccess(parsed.path));
      yield put(createToastRequest({ type: 'success', text: 'Resource was created successfully' }));
      if (callback) callback(parsed.path.id);
    } else if (response.status === 401) {
      yield put(authUrlRequest());
    } else {
      yield put(createResourceError('failed to create'));
      yield put(createToastRequest({ type: 'error', text: 'Error during create resource' }));
    }
  } catch (e) {
    yield put(createResourceError(e));
    yield put(createToastRequest({ type: 'error', text: 'Error during create resource' }));
  }
}

export function* removeResource(ops) {
  const authData = yield select(getAuth);
  const { data } = ops;
  const { id, api_id, revision } = data || {};
  try {
    const response = yield call(api, `/apis/${api_id}/paths/${id}`, 'DELETE', { revision }, authData);
    if (response.ok) {
      yield put(removeResourceSuccess({ id }));
      yield put(createToastRequest({ type: 'success', text: 'Resource was removed successfully' }));
    } else if (response.status === 401) {
      yield put(authUrlRequest());
    } else {
      yield put(removeResourceError('failed to remove resource'));
      yield put(createToastRequest({ type: 'error', text: 'Failed to remove resource' }));
    }
  } catch (e) {
    yield put(removeResourceError(e));
    yield put(createToastRequest({ type: 'error', text: 'Failed to remove resource' }));
  }
}

export function* editResource(ops) {
  const authData = yield select(getAuth);
  const { data } = ops;
  const { apiId, id, ...other } = data || {};
  try {
    const response = yield call(api, `/apis/${apiId}/paths/${id}`, 'PUT', { ...other }, authData);
    if (response.ok) {
      const parsed = yield response.json();
      yield put(editResourceSuccess({ ...other, ...parsed.path }));
      yield put(createToastRequest({ type: 'success', text: 'Resource was changed successfully' }));
    } else if (response.status === 401) {
      yield put(authUrlRequest());
    } else {
      yield put(editResourceError('failed to edit resource'));
      yield put(createToastRequest({ type: 'error', text: 'Failed to modify resource' }));
    }
  } catch (e) {
    yield put(editResourceError(e));
    yield put(createToastRequest({ type: 'error', text: 'Failed to modify resource' }));
  }
}

function* resourcesSaga() {
  yield takeEvery(Resources.getResourcesRequest, fetchResources);
  yield takeEvery(Resources.createResourceRequest, createResource);
  yield takeEvery(Resources.removeResourceRequest, removeResource);
  yield takeEvery(Resources.editResourceRequest, editResource);
}

function* configSaga() {
  yield all([fork(resourcesSaga)]);
}

export default configSaga;
