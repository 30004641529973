import { Auth } from './constants';

const initialState = {
  auth: null,
  currentUser: null,
};

export const authReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Auth.getTokenSuccess:
      return { ...state, auth: action.data };
    case Auth.refreshTokenSuccess:
      return { ...state, auth: action.data };
    case Auth.meSuccess:
      return { ...state, currentUser: action.data };
    case Auth.logoutSuccess:
      return initialState;
    default:
      return state;
  }
};
