import {
  call, put, takeEvery, all, fork, select,
} from 'redux-saga/effects';
import { api } from '../../helpers/api';
import {
  getConfigSuccess, getConfigError,
} from './actions';
import {
  authUrlRequest,
} from '../user/actions';
import { createToastRequest } from '../toasts/actions';

import { Config } from './constants';

const getAuth = (state) => state.auth?.auth;

export function* fetchConfig() {
  const authData = yield select(getAuth);
  try {
    const response = yield call(api, '/config', 'GET', null, authData);
    if (response.ok) {
      const data = yield response.json();
      yield put(getConfigSuccess(data.config));
    } else if (response.status === 401) {
      yield put(authUrlRequest());
    } else {
      yield put(getConfigError('failed to fetch config'));
      yield put(createToastRequest({ type: 'error', text: 'Failed to fetch Config' }));
    }
  } catch (e) {
    yield put(getConfigError(e));
    yield put(createToastRequest({ type: 'error', text: 'Failed to fetch Config' }));
  }
}

function* resourcesSaga() {
  yield takeEvery(Config.getConfigRequest, fetchConfig);
}

function* configSaga() {
  yield all([fork(resourcesSaga)]);
}

export default configSaga;
