import {
  call, put, takeEvery, all, fork, select,
} from 'redux-saga/effects';
import { api } from '../../helpers/api';
import {
  getApisSuccess, getApisError,
  createApiSuccess, createApiError,
  removeApiSuccess, removeApiError,
} from './actions';
import {
  authUrlRequest,
} from '../user/actions';

import { createToastRequest } from '../toasts/actions';

import { Apis } from './constants';

const getAuth = (state) => state.auth?.auth;

export function* fetchApis(ops) {
  const authData = yield select(getAuth);
  const { data } = ops;
  const { id } = data;
  try {
    const response = yield call(api, `/apis?owner_id=${id}`, 'GET', null, authData);
    if (response.ok) {
      const parsed = yield response.json();
      yield put(getApisSuccess(parsed));
    } else if (response.status === 401) {
      yield put(authUrlRequest());
    } else {
      yield put(getApisError('failed to fetch apis'));
      yield put(createToastRequest({ type: 'error', text: 'Failed to fetch APis' }));
    }
  } catch (e) {
    yield put(getApisError(e));
    yield put(createToastRequest({ type: 'error', text: 'Failed to fetch APis' }));
  }
}

export function* createApi(ops) {
  const authData = yield select(getAuth);
  const { callback, data } = ops;
  try {
    const response = yield call(api, '/apis', 'POST', data, authData);
    if (response.ok) {
      const parsed = yield response.json();
      yield put(createApiSuccess(parsed.api));
      yield put(createToastRequest({ type: 'success', text: 'Api was created successfully' }));
      if (callback) callback();
    } else if (response.status === 401) {
      yield put(authUrlRequest());
    } else {
      yield put(createApiError('Failed to create API'));
      yield put(createToastRequest({ type: 'error', text: 'Failed to create APi' }));
    }
  } catch (e) {
    yield put(createApiError(e));
    yield put(createToastRequest({ type: 'error', text: 'Failed to create APi' }));
  }
}

export function* removeApi(ops) {
  const authData = yield select(getAuth);
  const { data } = ops;
  const { apiId, ...other } = data || {};
  try {
    const response = yield call(api, `/apis/${apiId}`, 'DELETE', { ...other }, authData);
    if (response.ok) {
      yield put(removeApiSuccess({ id: apiId }));
      yield put(createToastRequest({ type: 'success', text: 'Api was removed successfully' }));
    } else if (response.status === 401) {
      yield put(authUrlRequest());
    } else {
      yield put(removeApiError('failed to remove api'));
      yield put(createToastRequest({ type: 'error', text: 'Failed to remove api' }));
    }
  } catch (e) {
    yield put(removeApiError(e));
    yield put(createToastRequest({ type: 'error', text: 'Failed to remove api' }));
  }
}

function* apisSaga() {
  yield takeEvery(Apis.getApisRequest, fetchApis);
  yield takeEvery(Apis.createApiRequest, createApi);
  yield takeEvery(Apis.removeApiRequest, removeApi);
}

function* configSaga() {
  yield all([fork(apisSaga)]);
}

export default configSaga;
