/* eslint-disable camelcase */
import {
  call, put, takeEvery, takeLatest, all, fork, select, delay,
} from 'redux-saga/effects';
import { api } from '../../helpers/api';
import {
  meSuccess, meError,
  authUrlRequest, authUrlSuccess, authUrlError,
  logoutSuccess, logoutError,
  getTokenSuccess, getTokenError,
  refreshTokenRequest, refreshTokenSuccess, refreshTokenError,
} from './actions';
import { Auth } from './constants';

import { createToastRequest } from '../toasts/actions';

const getConfig = (state) => state.config;
const getAuth = (state) => state.auth?.auth;

export function* refreshToken() {
  const authData = yield select(getAuth);
  const { expire_at } = authData || {};
  const currentUnixtime = Math.floor(Date.now() / 1000);
  const leftToExpire = (expire_at - currentUnixtime) > 300 ? expire_at - currentUnixtime - 300 : 0;
  yield delay(leftToExpire * 1000);

  if (currentUnixtime < expire_at) {
    try {
      const response = yield call(api, '/auth/refresh', 'POST', null, authData);
      if (response.ok) {
        const parsed = yield response.json();
        localStorage.setItem('token', JSON.stringify(parsed));

        yield put(refreshTokenSuccess(parsed));
        yield put(refreshTokenRequest());
      } else {
        yield put(refreshTokenError('Failed to refresh token'));
        yield put(createToastRequest({ type: 'error', text: 'Your token expired' }));
      }
    } catch (e) {
      yield put(refreshTokenError(e));
      yield put(createToastRequest({ type: 'error', text: 'Your token expired' }));
    }
  } else {
    yield put(authUrlRequest());
  }
}

export function* fetchMe() {
  const authData = yield select(getAuth);
  try {
    const response = yield call(api, '/auth/me', 'GET', null, authData);
    if (response.ok) {
      const data = yield response.json();
      yield put(meSuccess(data));
    } else if (response.status === 401) {
      yield put(authUrlRequest());
    } else {
      yield put(meError('Cant login'));
      yield put(createToastRequest({ type: 'error', text: 'Failed to fetch user data' }));
    }
  } catch (e) {
    yield put(meError(e));
    yield put(createToastRequest({ type: 'error', text: 'Failed to fetch user data' }));
  }
}

export function* fetchAuthUrl() {
  try {
    const response = yield call(api, '/auth/login', 'GET');
    if (response.ok) {
      const { redirect_uri } = yield response.json();
      yield put(authUrlSuccess());
      localStorage.setItem('path', JSON.stringify(window.location.href));

      if (redirect_uri) window.location.href = redirect_uri;
    } else {
      yield put(authUrlError('Auth url error'));
      yield put(createToastRequest({ type: 'error', text: 'Error during generating token' }));
    }
  } catch (e) {
    yield put(authUrlError(e));
    yield put(createToastRequest({ type: 'error', text: 'Error during generating token' }));
  }
}

export function* logout() {
  const authData = yield select(getAuth);
  const configData = yield select(getConfig);
  const { single_account_http_host: signleAccountHost } = configData || {};
  const removeCookieHost = signleAccountHost || 'https://account.pre.corezoid.com';

  try {
    const response = yield call(api, '/auth/logout', 'POST', null, authData);
    if (response.ok || response.status === 401) {
      yield put(logoutSuccess());
      localStorage.removeItem('token');
      window.location.href = `${removeCookieHost}/auth/dist_logout?redirect_uri=${encodeURIComponent(window.location.href)}`;
    } else {
      yield put(logoutError('failed to logout'));
      yield put(createToastRequest({ type: 'error', text: 'failed to logout' }));
    }
  } catch (e) {
    yield put(logoutError(e));
    yield put(createToastRequest({ type: 'error', text: 'failed to logout' }));
  }
}

export function* fetchToken(ops) {
  const { data } = ops;
  const path = JSON.parse(localStorage.getItem('path')) || '/';
  localStorage.removeItem('path');

  try {
    const response = yield call(api, '/auth/login/return', 'POST', data);
    if (response.ok) {
      const parsed = yield response.json();
      yield put(getTokenSuccess(parsed));
      localStorage.setItem('token', JSON.stringify(parsed));

      window.location.href = path;
    } else {
      yield put(getTokenError('failed to get token'));
      yield put(createToastRequest({ type: 'error', text: 'Failed to get token' }));
    }
  } catch (e) {
    yield put(getTokenError(e));
    yield put(createToastRequest({ type: 'error', text: 'Failed to get token' }));
  }
}

function* authSaga() {
  yield takeEvery(Auth.meRequest, fetchMe);
  yield takeLatest(Auth.authUrlRequest, fetchAuthUrl);
  yield takeEvery(Auth.getTokenRequest, fetchToken);
  yield takeLatest(Auth.refreshTokenRequest, refreshToken);
  yield takeEvery(Auth.logoutRequest, logout);
}

function* configSaga() {
  yield all([fork(authSaga)]);
}

export default configSaga;
