/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import {
  Table, IconButton, Button, Modal,
} from 'rsuite';
import TrashIcon from '@rsuite/icons/Trash';
import { connect } from 'react-redux';
import { removeResourceRequest } from './store/resources/actions';

function ActionCell({
  removeResource, rowData, dataKey, ...props
}) {
  const {
    path, method,
  } = rowData;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleSubmit = () => {
    removeResource(rowData);
    handleClose();
  };
  return (
    <Table.Cell {...props}>
      <IconButton appearance="subtle" onClick={handleOpen} icon={<TrashIcon />} />
      <Modal size="sm" open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Destroy Resource</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Resource
            {' '}
            {method}
            {' '}
            <strong>{path}</strong>
            {' '}
            will be Destroyed Completely
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
          <Button onClick={handleSubmit} appearance="primary" color="red">
            Destroy
          </Button>
        </Modal.Footer>
      </Modal>
    </Table.Cell>
  );
}

function TableResource({ resourcesData = [], removeResource }) {
  const [sortColumn, setSortColumn] = useState();
  const [sortType, setSortType] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (resourcesData) setLoading(false);
  }, [resourcesData]);

  const getData = () => {
    if (sortColumn && sortType) {
      return resourcesData && resourcesData.length && resourcesData.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
          x = x.charCodeAt();
        }
        if (typeof y === 'string') {
          y = y.charCodeAt();
        }
        if (sortType === 'asc') {
          return x - y;
        }
        return y - x;
      });
    }
    return resourcesData;
  };

  const handleSortColumn = (sC, sT) => {
    setSortColumn(sC);
    setSortType(sT);
  };

  return (
    <div style={{
      height: '100%', padding: '20px',
    }}
    >
      <Table
        data={getData()}
        fillHeight
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
        loading={loading}
      >
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Path</Table.HeaderCell>
          <Table.Cell dataKey="path" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Method</Table.HeaderCell>
          <Table.Cell dataKey="method" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Process ID</Table.HeaderCell>
          <Table.Cell dataKey="process_id" />
        </Table.Column>
        <Table.Column flexGrow={1}>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.Cell dataKey="description" />
        </Table.Column>
        <Table.Column width={100}>
          <Table.HeaderCell>Action</Table.HeaderCell>
          <ActionCell removeResource={removeResource} dataKey="id" />
        </Table.Column>
      </Table>
    </div>
  );
}

const mapStateToProps = ({ resources }) => ({
  resourcesData: resources,
});

const mapDispatchToProps = (dispatch) => ({
  removeResource: (data) => dispatch(removeResourceRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableResource);
