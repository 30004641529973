import React from 'react';
import { createRoot } from 'react-dom/client';
import { CustomProvider } from 'rsuite';

import App from './App';

const rootElement = document.getElementById('app');
const root = createRoot(rootElement);

root.render(
  <CustomProvider>
    <App />
  </CustomProvider>,
);
