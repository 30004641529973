/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import {
  Navbar, Container, Header, Sidebar, Sidenav, Nav, Divider, Avatar,
  Loader, Whisper, Popover, Button,
} from 'rsuite';
import StorageIcon from '@rsuite/icons/Storage';
import TableIcon from '@rsuite/icons/Table';
import PlusIcon from '@rsuite/icons/Plus';
import ResourcePanel from './ResourcePanel';
import {
  refreshTokenRequest, meRequest, getTokenRequest, logoutRequest,
} from '../store/user/actions';
import { getConfigRequest } from '../store/config/actions';
import { getApisRequest } from '../store/apis/actions';

function Page({
  children, getUser, getToken, getConfig, user: data,
  apis: apisData, getApis, signOut, refreshToken,
}) {
  const { pathname, search } = useLocation();
  const firstPath = pathname.split('/')[1];

  const activeSidepanel = firstPath && firstPath !== 'create' && firstPath !== 'table' && apisData && apisData.find((el) => el.id == firstPath);

  const queryValues = queryString.parse(search);

  useEffect(() => {
    if (queryValues.code) {
      getToken({ code: queryValues.code });
    } else {
      getUser();
      refreshToken();
    }
  }, []);

  useEffect(() => {
    const { id } = data || {};
    if (id) getApis({ id });
    if (id) getConfig();
  }, [data]);

  const speaker = (
    <Popover>
      <Button onClick={signOut} style={{ minWidth: 100 }} appearance="subtle">Log out</Button>
    </Popover>
  );
  return (
    <>
      {!data ? (
        <Loader center backdrop={false} size="lg" speed="slow" />
      ) : (
        <div>
          <Container>
            <Header style={{ borderBottom: '1px solid white' }}>
              <Navbar appearance="inverse">
                <Navbar.Brand style={{ fontWeight: 'bold' }}>
                  API Gateway
                </Navbar.Brand>
                <Nav pullRight>
                  <Nav.Item>
                    <Whisper
                      trigger="click"
                      placement="bottomEnd"
                      speaker={speaker}
                    >
                      <Avatar circle src={data.user_photo} />
                    </Whisper>

                  </Nav.Item>
                </Nav>
              </Navbar>
            </Header>
            <Container style={{ height: 'calc(100vh - 56px)' }}>
              <Sidebar
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  backgroundColor: 'var(--rs-sidenav-inverse-bg)',
                }}
                width={300}
                height="100%"
                collapsible
              >
                <Sidenav expanded defaultOpenKeys={['3']} appearance="inverse">
                  <Sidenav.Body>
                    <Nav appearance="tabs">
                      <Nav.Item as={NavLink} active={pathname === '/create'} eventKey="1" to="/create" icon={<PlusIcon />}>
                        New Endpoint
                      </Nav.Item>
                      <Nav.Item as={NavLink} active={pathname === '/'} eventKey="2" to="/" icon={<TableIcon />}>
                        All
                      </Nav.Item>
                      <Divider />
                    </Nav>
                    <Nav style={{ maxHeight: 'calc(100vh - 256px)', overflow: 'scroll' }}>
                      {apisData && apisData.length ? apisData.map((api) => (
                        <Nav.Item
                          icon={<StorageIcon />}
                          key={api.id}
                          as={NavLink}
                          active={pathname.startsWith(`/${api.id}`)}
                          to={`/${api.id}`}
                        >
                          {api.name}
                        </Nav.Item>
                      )) : (
                        <>
                          <span style={{ marginLeft: '24px' }}>Apis list emty</span>

                        </>
                      )}
                    </Nav>
                  </Sidenav.Body>
                </Sidenav>
              </Sidebar>
              {activeSidepanel && (
                <ResourcePanel activeSidepanel={activeSidepanel} />
              )}
              <Container>
                <div style={{ height: '100%' }}>
                  {children}
                </div>
              </Container>
            </Container>
          </Container>
        </div>
      )}
    </>
  );
}

const mapStateToProps = ({ auth: { currentUser }, apis }) => ({
  user: currentUser,
  apis,
});

const mapDispatchToProps = (dispatch) => ({
  getConfig: () => dispatch(getConfigRequest()),
  getUser: () => dispatch(meRequest()),
  refreshToken: () => dispatch(refreshTokenRequest()),
  getToken: (data) => dispatch(getTokenRequest(data)),
  getApis: (data) => dispatch(getApisRequest(data)),
  signOut: () => dispatch(logoutRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Page);
