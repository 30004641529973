import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootSaga from './rootSaga';
import rootReducer from './rootReducer';

const authInitial = localStorage.getItem('token')
  ? { auth: JSON.parse(localStorage.getItem('token')), currentUser: null }
  : { auth: null, currentUser: null };

// we need an initialState otherwise , store will freak out
const initialState = {
  auth: authInitial,
  resources: [],
  apis: [],
  config: null,
  toasts: null,
};

export default function configureStore() {
  // create the composing function for our middlewares
  const composeEnhancers = composeWithDevTools({});
  // create the redux-saga middleware
  const sagaMiddleware = createSagaMiddleware();

  // We'll create our store with the combined reducers/sagas, and the initial Redux state that
  // we'll be passing from our entry point.
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware)),
  );

  // Don't forget to run the root saga, and return the store object.
  sagaMiddleware.run(rootSaga);
  return store;
}
