import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import CloseIcon from '@rsuite/icons/Close';
import { removeToastRequest } from '../store/toasts/actions';

import './Toasts.scss';

function Toast({ item, action }) {
  const { type = 'success', text, id } = item;
  return (
    <div className={cn('toast', {
      toastError: type === 'error',
      toastInfo: type === 'info',
    })}
    >
      <div className="toastGroup">
        <div className="toastgroupContent">
          {text}
        </div>
      </div>
      <div>
        <button
          type="button"
          onClick={() => action(id)}
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  );
}

Toast.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
  action: PropTypes.func.isRequired,
};

function Toasts({ toasts = [], removeToast }) {
  const handleAction = (id) => {
    removeToast({ id });
  };

  return (
    <div className="toastsContainer">
      {toasts && toasts.length ? toasts.map((t) => (
        <Toast
          key={t.key}
          item={t}
          action={handleAction}
        />
      )) : (
        <div />
      )}
    </div>
  );
}

Toasts.propTypes = {
  removeToast: PropTypes.func.isRequired,
  toasts: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = ({ toasts }) => ({
  toasts,
});

const mapDispatchToProps = (dispatch) => ({
  removeToast: (data) => dispatch(removeToastRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);
