/* eslint-disable import/prefer-default-export */
export const Auth = {
  meRequest: 'ME_PENDING',
  meSuccess: 'ME_SUCCESS',
  meError: 'ME_ERROR',

  authUrlRequest: 'AUTH_URL_REQUEST',
  authUrlSuccess: 'AUTH_URL_SUCCESS',
  authUrlError: 'AUTH_URL_ERROR',

  logoutRequest: 'LOGOUT_REQUEST',
  logoutSuccess: 'LOGOUT_SUCCESS',
  logoutError: 'LOGOUT_ERROR',

  getTokenRequest: 'GET_TOKEN_REQUEST',
  getTokenSuccess: 'GET_TOKEN_SUCCESS',
  getTokenError: 'GET_TOKEN_ERROR',

  refreshTokenRequest: 'REFRESH_TOKEN_REQUEST',
  refreshTokenSuccess: 'REFRESH_TOKEN_SUCCESS',
  refreshTokenError: 'REFRESH_TOKEN_ERROR',
};
