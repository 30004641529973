export function api(route, method, data, auth) {
  const { token } = auth || {};
  const headers = {
    'Content-Type': 'application/json',
  };
  const authHeader = token ? `Bearer ${token}` : undefined;
  if (authHeader) {
    headers.Authorization = authHeader;
  }
  return fetch(`/api${route}`, {
    method: method || 'GET',
    headers,
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((response) => response)
    .catch((error) => {
      throw error;
    });
}
