/* eslint-disable import/prefer-default-export */
export const Resources = {
  getResourcesRequest: 'GET_RESOURCES_REQUEST',
  getResourcesSuccess: 'GET_RESOURCES_SUCCESS',
  getResourcesError: 'GET_RESOURCES_ERROR',

  createResourceRequest: 'CREATE_RESOURCE_REQUEST',
  createResourceSuccess: 'CREATE_RESOURCE_SUCCESS',
  createResourceError: 'CREATE_RESOURCE_ERROR',

  removeResourceRequest: 'REMOVE_RESOURCE_REQUEST',
  removeResourceSuccess: 'REMOVE_RESOURCE_SUCCESS',
  removeResourceError: 'REMOVE_RESOURCE_ERROR',

  editResourceRequest: 'EDIT_RESOURCE_REQUEST',
  editResourceSuccess: 'EDIT_RESOURCE_SUCCESS',
  editResourceError: 'EDIT_RESOURCE_ERROR',
};
