/* eslint-disable max-len */
import { Resources } from './constants';

export const getResourcesRequest = (data) => ({ type: Resources.getResourcesRequest, data });
export const getResourcesSuccess = (data) => ({ type: Resources.getResourcesSuccess, data });
export const getResourcesError = (err) => ({ type: Resources.getResourcesError, err });

export const createResourceRequest = (data, callback) => ({ type: Resources.createResourceRequest, data, callback });
export const createResourceSuccess = (data) => ({ type: Resources.createResourceSuccess, data });
export const createResourceError = (err) => ({ type: Resources.createResourceError, err });

export const removeResourceRequest = (data) => ({ type: Resources.removeResourceRequest, data });
export const removeResourceSuccess = (data) => ({ type: Resources.removeResourceSuccess, data });
export const removeResourceError = (err) => ({ type: Resources.removeResourceError, err });

export const editResourceRequest = (data) => ({ type: Resources.editResourceRequest, data });
export const editResourceSuccess = (data) => ({ type: Resources.editResourceSuccess, data });
export const editResourceError = (err) => ({ type: Resources.editResourceError, err });
