/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Form, Schema, ButtonToolbar, Button, Input, Content,
} from 'rsuite';
import { createApiRequest } from './store/apis/actions';

const model = Schema.Model({
  name: Schema.Types.StringType().minLength(3, 'Minimum 3 characters required').isRequired('This field required'),
});

const initFormValues = {
  name: '',
  description: '',
};

// eslint-disable-next-line react/jsx-props-no-spreading
const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />);

function CreateEndpoint({ user, createApi }) {
  const [val, setVal] = useState(initFormValues);
  const [checkTrigger, setCheckTrigger] = useState('none');

  const handleVal = (e) => setVal(e);

  const handleSubmit = () => {
    const { id } = user || {};
    setCheckTrigger('change');
    if (val.name.length > 2) createApi({ owner_id: id, ...val }, () => { setVal(initFormValues); });
  };

  return (
    <Content style={{ height: '100%' }}>
      <div style={{
        display: 'flex', height: '100%', margin: 'auto', alignItems: 'center', justifyContent: 'center',
      }}
      >
        <Form
          formValue={val}
          onChange={handleVal}
          onSubmit={handleSubmit}
          model={model}
          checkTrigger={checkTrigger}
        >
          <Form.Group controlId="name">
            <Form.ControlLabel>API name</Form.ControlLabel>
            <Form.Control name="name" />
          </Form.Group>
          <Form.Group controlId="description">
            <Form.ControlLabel>
              {' '}
              Description
              {' '}
              <em>(Optional)</em>
            </Form.ControlLabel>
            <Form.Control rows={4} name="description" accepter={Textarea} />
          </Form.Group>
          <Form.Group>
            <ButtonToolbar>
              <Button type="submit" appearance="primary">Add</Button>
            </ButtonToolbar>
          </Form.Group>
        </Form>
      </div>
    </Content>
  );
}

const mapStateToProps = ({ auth: { currentUser } }) => ({
  user: currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  createApi: (ops, callback) => dispatch(createApiRequest(ops, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEndpoint);
