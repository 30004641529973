import {
  put, takeEvery, all, fork, delay,
} from 'redux-saga/effects';
import {
  createToastSuccess, removeToastSuccess,
} from './actions';
import { Toasts } from './constants';
import Incrementor from '../../helpers/uidGenerator';

const uid = new Incrementor();

export function* createToast(ops) {
  const { data } = ops;
  const id = uid.genearateUid();

  yield put(createToastSuccess({ ...data, id }));
  yield delay(6000);
  yield put(removeToastSuccess({ id }));
}

export function* removeToast(ops) {
  const { data } = ops;
  yield put(removeToastSuccess(data));
}

function* toastsSaga() {
  yield takeEvery(Toasts.createToastRequest, createToast);
  yield takeEvery(Toasts.removeToastRequest, removeToast);
}

function* configSaga() {
  yield all([fork(toastsSaga)]);
}

export default configSaga;
