/* eslint-disable import/prefer-default-export */
export const Apis = {
  getApisRequest: 'GET_APIS_REQUEST',
  getApisSuccess: 'GET_APIS_SUCCESS',
  getApisError: 'GET_APIS_ERROR',

  createApiRequest: 'CREATE_API_REQUEST',
  createApiSuccess: 'CREATE_API_SUCCESS',
  createApiError: 'CREATE_API_ERROR',

  removeApiRequest: 'REMOVE_API_REQUEST',
  removeApiSuccess: 'REMOVE_API_SUCCESS',
  removeApiError: 'REMOVE_API_ERROR',
};
