/* eslint-disable max-len */
import { Apis } from './constants';

export const getApisRequest = (data) => ({ type: Apis.getApisRequest, data });
export const getApisSuccess = (data) => ({ type: Apis.getApisSuccess, data });
export const getApisError = (err) => ({ type: Apis.getApisError, err });

export const createApiRequest = (data, callback) => ({ type: Apis.createApiRequest, data, callback });
export const createApiSuccess = (data) => ({ type: Apis.createApiSuccess, data });
export const createApiError = (err) => ({ type: Apis.createApiError, err });

export const removeApiRequest = (data) => ({ type: Apis.removeApiRequest, data });
export const removeApiSuccess = (data) => ({ type: Apis.removeApiSuccess, data });
export const removeApiError = (err) => ({ type: Apis.removeApiError, err });
