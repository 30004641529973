import { Apis } from './constants';

const initialState = [];

export const apisReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Apis.getApisSuccess:
      return action.data;
    case Apis.createApiSuccess:
      return state ? [...state, action.data] : [action.data];
    case Apis.removeApiSuccess:
      return [
        ...state.filter((el) => el.id !== action.data.id),
      ];
    default:
      return state;
  }
};
