/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Sidebar, Nav, Sidenav, IconButton,
} from 'rsuite';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TableIcon from '@rsuite/icons/Table';
import PlusIcon from '@rsuite/icons/Plus';
import CopyIcon from '@rsuite/icons/Copy';
import { getResourcesRequest } from '../store/resources/actions';
import { createToastRequest } from '../store/toasts/actions';

function ResourcePanel({
  activeSidepanel, getResources, resources: resourcesData, addToast,
}) {
  const { pathname } = useLocation();
  const firstPath = pathname.split('/')[1];
  const secondPath = pathname.split('/')[2];

  const clickCopy = () => {
    addToast({ type: 'info', text: 'Path was copied' });
  };

  useEffect(() => {
    if (activeSidepanel?.id) getResources({ id: activeSidepanel.id });
  }, [activeSidepanel]);

  const colorPicker = (method) => {
    switch (method) {
      case 'GET':
        return 'green';
      case 'POST':
        return 'orange';
      case 'PUT':
        return 'blue';
      case 'DELETE':
        return 'red';
      case 'PATCH':
        return 'black';
      default:
        return 'black';
    }
  };

  return (
    <Sidebar
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        backgroundColor: 'var(--rs-sidenav-default-bg)',
      }}
      width={350}
      height="100%"
      collapsible
    >
      <Sidenav expanded appearance="default">
        <Sidenav.Body>
          <Nav appearance="tabs">
            <h4 style={{ fontWeight: '600', padding: '8px 12px' }}>
              <span style={{ marginRight: '4px' }}>{activeSidepanel.name}</span>
              <CopyToClipboard
                text={activeSidepanel.host}
                onCopy={() => clickCopy()}
              >
                <IconButton size="sm" icon={<CopyIcon />} />
              </CopyToClipboard>
            </h4>
            <p style={{ fontWeight: '600', padding: '8px 12px' }}>
              <span>{activeSidepanel.host}</span>
            </p>
          </Nav>
          <Nav appearance="tabs">
            <Nav.Item as={NavLink} active={pathname === `/${firstPath}/create`} eventKey="1" to={`/${firstPath}/create`} replace icon={<PlusIcon />}>
              Add Resource
            </Nav.Item>
            <Nav.Item as={NavLink} active={pathname === `/${firstPath}`} eventKey="2" to={`/${firstPath}`} replace icon={<TableIcon />}>
              All
            </Nav.Item>
          </Nav>
          <hr />
          <Nav style={{ maxHeight: 'calc(100vh - 302px)', overflow: 'scroll' }}>
            {resourcesData?.length ? resourcesData.map((res) => (
              <Nav.Item
                style={{ paddingLeft: '20px', display: 'flex', flexDirection: 'row' }}
                key={res.id}
                as={NavLink}
                active={secondPath == res.id}
                to={`/${firstPath}/${res.id}`}
              >
                <span style={{
                  color: colorPicker(res.method),
                  fontSize: 10,
                  padding: 2,
                  border: `1px solid ${colorPicker(res.method)}`,
                  borderRadius: 2,
                  minWidth: 45,
                  marginRight: '10px',
                  display: 'inline-block',
                  textAlign: 'center',
                }}
                >
                  {res.method}
                </span>
                {' '}
                {res.path}
              </Nav.Item>
            )) : (
              <span style={{ marginLeft: '20px' }}>Resource list emty</span>
            )}
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    </Sidebar>
  );
}

const mapStateToProps = ({ resources }) => ({
  resources,
});

const mapDispatchToProps = (dispatch) => ({
  getResources: (data) => dispatch(getResourcesRequest(data)),
  addToast: (data) => dispatch(createToastRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResourcePanel);
