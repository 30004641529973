import { Resources } from './constants';

const initialState = [];

export const resourcesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case Resources.getResourcesSuccess:
      return action.data;
    case Resources.createResourceSuccess:
      return state ? [...state, action.data] : [action.data];
    case Resources.removeResourceSuccess:
      return [
        ...state.filter((el) => el.id !== action.data.id),
      ];
    case Resources.editResourceSuccess:
      return [...state.map(
        (content) => (Number(action.data.id) === Number(content.id) ? { ...content, ...action.data }
          : content),
      )];
    default:
      return state;
  }
};
